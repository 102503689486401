/** @jsx jsx */
import { jsx } from "theme-ui"

import Column from "../components/column"
import Container from "../components/container"
import ProjectCard from "./project-card"
import PageLink from "./page-link"
import Row from "../components/row"

const FeaturedProjects = ({ data }) => {
  const fullWidthList = [true, true, true]
  const renderProjectCard = (projectIndex, type) => {
    const projectList = data.edges

    return projectList[projectIndex] ? (
      <PageLink
        link={`/projects/${projectList[projectIndex].node.slug}`}
        type="internal"
      >
        <ProjectCard
          background={projectList[projectIndex].node.projectImages[0].fluid.src}
          text={[
            projectList[projectIndex].node.projectTitle.projectTitle,
          ].concat(
            projectList[projectIndex].node.projectLocation.projectLocation
          )}
          type={type || "primary"}
        />
      </PageLink>
    ) : null
  }

  return (
    <Container customStyle={{ pb: 5 }} fullWidth={fullWidthList}>
      <Row noGutters={fullWidthList}>
        <Column size={[12, 12, 6]}>
          <Row>
            <Column customStyle={{ pr: [2, 2, 1], pl: 2 }}>
              {renderProjectCard(0)}
            </Column>
          </Row>
          <Row customStyle={{ mt: 2 }} size={[12, 12, 6]}>
            <Column customStyle={{ mb: [2, 2, 0], pr: [2, 2, 1], pl: 2 }}>
              {renderProjectCard(1, "secondary")}
            </Column>
            <Column
              customStyle={{ pr: [2, 2, 1], pl: [2, 2, 1] }}
              size={[12, 12, 6]}
            >
              {renderProjectCard(2, "secondary")}
            </Column>
          </Row>
        </Column>
        <Column>
          <Row>
            <Column
              customStyle={{
                mt: [2, 2, 0],
                mb: [2, 2, 0],
                pl: 1,
                pr: [2, 2, 1],
              }}
              size={[12, 12, 6]}
            >
              {renderProjectCard(3, "secondary")}
            </Column>
            <Column customStyle={{ pl: 1, pr: 2 }}>
              {renderProjectCard(4, "secondary")}
            </Column>
          </Row>
          <Row customStyle={{ mt: 2 }}>
            <Column customStyle={{ pl: 1, pr: 2 }}>
              {renderProjectCard(5)}
            </Column>
          </Row>
        </Column>
      </Row>
    </Container>
  )
}

export default FeaturedProjects
