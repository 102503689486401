/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import kebabCase from "lodash/kebabCase"
import { Flex } from "@theme-ui/components"

import { renderLineBreak, findBySlug } from "../helpers"

import Column from "../components/column"
import Container from "../components/container"
import CTA from "../components/cta"
import Hero from "../components/hero"
import FeaturedProjects from "../components/featured-projects"
import ModelSlider from "../components/model-slider"
import DesignPartnerCard from "../components/design-partner-card"
import Page from "../components/page"
import PageLink from "../components/page-link"
import Row from "../components/row"
import Text from "../components/text"

const DesignPartnersPage = ({ data }) => {
  const renderHeroBladePattern = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-design-partners-hero",
    })
    const heroHeights = [200, 300, 549]

    if (!heroBladePatternData) return
    // TODO: Stacked mobile hero
    return (
      <Hero
        customStyle={{
          height: heroHeights,
          maxHeight: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            mobileImage: heroBladePatternData.mobileImage,
            desktopImage: heroBladePatternData.desktopImage,
          },
        }}
        title={heroBladePatternData.title}
        body={heroBladePatternData.description.description}
        ctaList={[
          {
            link: heroBladePatternData.ctaDestination,
            type: "secondary",
            text: heroBladePatternData.ctaTitle,
          },
        ]}
      />
    )
  }
  const renderDesignMattersBladePattern = () => {
    const designMattersBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-design-matters",
    })

    if (!designMattersBladePatternData) return

    return (
      <div
        sx={{
          pb: theme => theme.spacing.vertical.lg,
        }}
      >
        <Container
          customStyle={{
            backgroundColor: theme => [
              theme.colors.backgrounds.primary,
              theme.colors.white,
            ],
            py: theme => [
              theme.spacing.vertical.md,
              theme.spacing.vertical.md,
              theme.spacing.vertical.lg,
            ],
          }}
        >
          <Row>
            <Column size={[12, 12, 4]}>
              <Text type="h2">
                {renderLineBreak(designMattersBladePatternData.title)}
              </Text>
            </Column>
            <Column size={[12, 12, 4]}>
              <Text
                type="p"
                customStyle={{
                  mt: theme => [
                    theme.spacing.vertical.sm,
                    theme.spacing.vertical.sm,
                    0,
                  ],
                }}
              >
                {designMattersBladePatternData.description.description}
              </Text>
            </Column>
          </Row>
        </Container>
        <Container>
          <Row>
            {data.allContentfulDesignPartner.edges.map(designPartner => (
              <Column
                size={[12, 6, 4]}
                customStyle={{
                  mt: theme => theme.spacing.horizontal,
                }}
                key={kebabCase(designPartner.node.name)}
              >
                <PageLink
                  link={`/architects/${designPartner.node.slug}`}
                  type="internal"
                >
                  <DesignPartnerCard
                    background={designPartner.node.photo.file.url}
                    text={designPartner.node.name}
                  />
                </PageLink>
              </Column>
            ))}
          </Row>
        </Container>
      </div>
    )
  }
  const renderSeeTheResultsBladePattern = () => {
    const seeTheResultsBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "design-partners-page-see-the-results",
    })

    if (!seeTheResultsBladePatternData) return

    return (
      <React.Fragment>
        <Container customStyle={{ pb: theme => theme.spacing.vertical.lg }}>
          <Row>
            <Column size={[12]}>
              <Text type="h2">
                {renderLineBreak(seeTheResultsBladePatternData.title)}
              </Text>
            </Column>
            <Column size={[12]}>
              <div
                sx={{
                  width: 280,
                  mt: theme => theme.spacing.vertical.sm,
                }}
              >
                <CTA
                  link={seeTheResultsBladePatternData.ctaDestination || ""}
                  type="secondary"
                >
                  {seeTheResultsBladePatternData.ctaTitle}
                </CTA>
              </div>
            </Column>
          </Row>
        </Container>
        <FeaturedProjects data={data.allContentfulProject} />
      </React.Fragment>
    )
  }
  const renderRecognitionBladePattern = () => {
    const recognitionBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "design-partners-recognition",
    })
    if (!recognitionBladePatternData) return

    return (
      <Container customStyle={{ py: theme => theme.spacing.vertical.md }}>
        <Row>
          <Column>
            <Text type="h3">
              {renderLineBreak(recognitionBladePatternData.title)}
            </Text>
          </Column>
        </Row>
        <Row
          customStyle={{
            mt: [
              theme => theme.spacing.vertical.sm,
              theme => theme.spacing.vertical.sm,
              theme => theme.spacing.vertical.md,
            ],
            p: theme => theme.spacing.horizontal,
          }}
        >
          {/* TODO: Rename contentCards to basicCardsList after updating Contentful model*/}
          {/* TODO: make this a component */}
          {recognitionBladePatternData.contentCards.map((basicCard, idx) => {
            return (
              <Column
                customStyle={{
                  alignItems: [
                    "flex-start",
                    "flex-start",
                    "flex-start",
                    "center",
                  ],
                  mb: [
                    theme => theme.spacing.vertical.sm,
                    theme => theme.spacing.vertical.sm,
                    theme => theme.spacing.vertical.sm,
                    0,
                  ],
                  ":last-child": { mb: 0 },
                }}
                size={[12, 12, 12, null]}
                key={kebabCase(basicCard.title)}
              >
                <Flex sx={{ alignItems: "center" }}>
                  <img
                    sx={{ maxWidth: 140 }}
                    src={basicCard.desktopImage.file.url}
                    alt=""
                  />
                  {basicCard.title && (
                    <Text customStyle={{ fontWeight: "heading", ml: 3 }}>
                      {renderLineBreak(basicCard.title)}
                    </Text>
                  )}
                </Flex>
              </Column>
            )
          })}
        </Row>
      </Container>
    )
  }
  const renderModelSliderBladePattern = () => {
    const modelSliderBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "design-partners-model-slider",
    })

    if (!modelSliderBladePatternData) return

    const modelData = {
      edges: data.allContentfulModelVariation.group.map(
        group => group.edges[0]
      ),
    }

    return (
      <Container customStyle={{ py: theme => theme.spacing.vertical.md }}>
        <Row>
          <Column>
            <Text type="h2">
              {renderLineBreak(modelSliderBladePatternData.title)}
            </Text>
          </Column>
        </Row>
        <Row>
          <ModelSlider data={modelData} />
        </Row>
      </Container>
    )
  }

  return (
    <Page data={data.contentfulPage}>
      {renderHeroBladePattern()}
      {renderDesignMattersBladePattern()}
      {renderSeeTheResultsBladePattern()}
      {renderRecognitionBladePattern()}
      {renderModelSliderBladePattern()}
    </Page>
  )
}

export default DesignPartnersPage

export const query = graphql`
  query designPartnersPageQuery {
    contentfulPage(slug: { eq: "architects" }) {
      ...PageData
      ...BladePatternData
    }

    allContentfulProject(limit: 6, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          projectImages {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
            id
            title
          }
          slug
          projectLocation {
            projectLocation
          }
          projectTitle {
            projectTitle
          }
        }
      }
    }

    allContentfulDesignPartner(limit: 6) {
      edges {
        node {
          name
          slug
          photo {
            file {
              fileName
              url
            }
          }
        }
      }
    }

    allContentfulModelVariation(sort: { fields: createdAt, order: DESC }) {
      group(field: model___designPartner___name, limit: 1) {
        edges {
          node {
            baths
            beds
            id
            images {
              fluid(maxWidth: 800) {
                ...GatsbyContentfulFluid_withWebp
              }
              id
              title
            }
            name
            squareFootage
            model {
              id
              slug
              designPartner {
                name
              }
            }
            createdAt
          }
        }
      }
    }
  }
`
