/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Text from "./text"

const DesignPartnerCard = ({ background, text, type }) => {
  const renderContent = () =>
    text ? (
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          p: 4,
        }}
      >
        <Text customStyle={{ color: "white" }}>{text}</Text>
      </div>
    ) : null

  return (
    <div
      sx={{
        ...(background && {
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          position: "relative",
        }),
        borderRadius: 5,
        display: "flex",
        height: 500,
        overflow: "hidden",
      }}
    >
      {renderContent()}
    </div>
  )
}

DesignPartnerCard.propTypes = {
  background: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
}

export default DesignPartnerCard
