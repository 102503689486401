/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import Carousel from "./carousel"
import PageLink from "./page-link"
import Text from "./text"

const ContentCard = ({
  baths,
  beds,
  imageList,
  squareFootage,
  title,
  titleLink,
}) => {
  const imageStyle = {
    display: "block",
    height: [235, 235, 400],
    m: 0,
    objectFit: "cover",
    objectPosition: "center",
    width: "100%",
  }
  const renderImage = (imageData, index) => {
    const imageID = `${imageData.id}-${index}`

    return imageData.fluid ? (
      <Img
        sx={{ height: imageStyle.height }}
        fluid={imageData.fluid}
        key={imageID}
      />
    ) : (
      <img
        sx={imageStyle}
        src={imageData.url || imageData.file.url}
        alt={imageData.title}
        key={imageID}
      />
    )
  }
  const renderCarousel = () => {
    return (
      <Carousel type="secondary">
        {imageList.map((imageData, index) => renderImage(imageData, index))}
      </Carousel>
    )
  }
  const renderTitle = () => {
    const titleContent = <Text type="h3">{title}</Text>

    return titleLink && titleLink.to ? (
      <PageLink link={titleLink.to} type={titleLink.type}>
        {titleContent}
      </PageLink>
    ) : (
      titleContent
    )
  }

  return (
    <div
      sx={{
        backgroundColor: "white",
        border: "1px solid #cccccc",
        borderRadius: 5,
        height: "100%",
      }}
    >
      {imageList.length > 1 && renderCarousel()}
      {imageList.length === 1 && renderImage(imageList[0])}
      <div sx={{ py: 3, px: 4 }}>
        {renderTitle()}
        <Text customStyle={{ fontWeight: "heading" }}>
          {beds} bed, {baths} bath, {squareFootage} sq ft
        </Text>
      </div>
    </div>
  )
}

ContentCard.propTypes = {
  title: PropTypes.string.isRequired,
  baths: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  beds: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  squareFootage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  imageList: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({ url: PropTypes.string.isRequired }),
      PropTypes.shape({ fluid: PropTypes.object.isRequired }),
    ]).isRequired
  ),
}

export default ContentCard
